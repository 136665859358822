






import {
    Vue, Component, Prop, Provide,
} from 'vue-property-decorator';

@Component({})
export default class TabsController extends Vue {
    @Prop({
        type: Array,
        default: () => ({}),
    })
    tabs!: any[];

    @Prop({
        type: Number,
        default: 0,
    })
    value!: number;

    get component() {
        return this.tabs[this.value];
    }

    @Provide('setTab')
    setTab(index: number) {
        this.$emit('input', index);
    }
}
